import React from 'react'
import { Link } from 'react-router-dom'

import Socials from 'components/ui/socials'
import Logo from 'components/ui/logo'

import mirLogo from 'assets/img/mir-logo.png'
import visaLogo from 'assets/img/visa-logo.png'
import mastercardLogo from 'assets/img/mastercard-logo.png'
import youmoneyLogo from 'assets/img/youmoney.png'
import sberPayLogo from 'assets/img/sberpay.png'

import homeIcon from 'assets/img/home-icon.svg'
import promoIcon from 'assets/img/promo-icon.svg'
import profileIcon from 'assets/img/profile-white-icon.svg'
import privacyIcon from 'assets/img/privacy-icon.svg'
import agreementIcon from 'assets/img/agreement-icon.svg'
import contactsIcon from 'assets/img/contacts-icon.svg'

import styles from './footer.module.scss'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.left}>
        <div className={styles.logo}>
          <Logo variant='light' />
        </div>
        <div className={styles.info}>
          <p>ООО «КАДР»</p>
          <p>ИНН 9703175473</p>
          <p>ОГРН 1247700205400</p>
        </div>
        <div className={styles.copy}>
          <p>&copy; Все права защищены. 2023 - {new Date().getFullYear()}</p>
        </div>
      </div>
      <div className={styles.center}>
        <div className={styles.links}>
          <Link
            className={styles.links_item}
            to='/'
          >
            <span>Главная</span>
          </Link>
          <Link
            className={styles.links_item}
            to='/me'
          >
            <span>Профиль</span>
          </Link>
          <Link
            className={styles.links_item}
            to='/promotion'
          >
            <span>Презентация</span>
          </Link>
          <Link
            className={styles.links_item}
            to='/about'
          >
            <span>О проекте</span>
          </Link>
          <Link
            className={styles.links_item}
            to='/rates'
          >
            <span>Тарифы</span>
          </Link>
          <Link
            className={styles.links_item}
            to='/contacts'
          >
            <span>Контакты</span>
          </Link>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.socials}>
          <Socials items={['tg', 'vk', 'inst', 'rutube', 'youtube']} />
        </div>
        <h4>Доступные способы оплаты</h4>
        <div className={styles.payment}>
          <div className={styles.payment_variant}>
            <img
              src={mirLogo}
              alt='Логотип Мир'
            />
          </div>
          <div className={styles.payment_variant}>
            <img
              src={visaLogo}
              alt='Логотип Visa'
            />
          </div>
          <div className={styles.payment_variant}>
            <img
              src={youmoneyLogo}
              alt='Логотип Юмани'
            />
          </div>
          <div className={styles.payment_variant}>
            <img
              src={mastercardLogo}
              alt='Логотип MasterCard'
            />
          </div>
          <div className={styles.payment_variant}>
            <img
              src={sberPayLogo}
              alt='Логотип SberPay'
            />
          </div>
        </div>
        <div className={styles.links_privacy}>
          <Link
            className={styles.links_privacy_item}
            to='/privacy'
          >
            <span>Политика конфиденциальности</span>
          </Link>
          <Link
            className={styles.links_privacy_item}
            to='/agreement'
          >
            <span>Соглашение</span>
          </Link>
        </div>
      </div>
    </footer>
  )
}

export default Footer
