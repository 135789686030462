import React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

import Rates from '../../components/ui/rates'

import styles from './rates.module.scss'

const RatesPage = ({ isLoading, isError }) => {
  return (
    <section className={styles.prices}>
      <div className={styles.container}>
        <div className={styles.title}>
          <h1>
            Тарифы и <span>Цены</span>
          </h1>
          <p>
            В этом разделе находится информация по доступным тарифам и ценам за доступ к просмотру
            сериала "Герой". Пожалуйста внимательно ознакомьтесь с условиями офромления подписки, а
            так же посмотрите <Link to={'/agreement'}>условия пользовательского соглашения</Link>
          </p>
        </div>

        <Rates
          isLoading={isLoading}
          isError={isError}
        />
      </div>
    </section>
  )
}

export default RatesPage
