import React from 'react'
import { Link } from 'react-router-dom'

import styles from './not-found.module.scss'

const NotFoundPage = () => {
  return (
    <section className={styles.not_found}>
      <h1>404</h1>
      <h3>Ошибка!</h3>
      <p>Страница не найдена.</p>
      <p>Возможно она переехала или никогда не существовала.</p>
      <Link
        className={'btn second'}
        to={'/'}
      >
        На главную
      </Link>
    </section>
  )
}

export default NotFoundPage
