export const links = [
  {
    to: '/me',
    title: 'Профиль',
  },
  // {
  //   to: '/videos',
  //   title: 'Смотреть',
  // },
  {
    to: '/about',
    title: 'О проекте',
  },
  {
    to: '/rates',
    title: 'Тарифы',
  },
  {
    to: '/contacts',
    title: 'Контакты',
  },
]
