import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom'

import { useGetRatesQuery } from './store/slices/rate/rateApiSlice'
import { useGetMeMutation, useGetMeQuery } from './store/slices/auth/authApiSlice'
import { setRates } from './store/slices/rate/rateSlice'
import { setUser } from './store/slices/auth/authSlice'

import { useGetSubscriptionsMutation } from './store/slices/subscription/subscriptionApiSlice'
import { setSubs } from './store/slices/subscription/subscriptionSlice'

import { notify } from './utils/notify'

import { ProtectedRoute } from './components/protected-route'
import Layout from './components/layout'
import VideoCard from './components/ui/video-card'
import HomePage from './pages/home'
// import Auth, {
//   LoginPage,
//   RegisterPage,
//   RecoveryPasswordPage,
//   ChangePasswordPage,
//   ConfirmationPage,
// } from './pages/auth/login'
import Auth from './pages/auth'
import CheckOTP from './pages/auth/check'
import LoginPage from './pages/auth/login'
import RegisterPage from './pages/auth/register'
import RecoveryPage from './pages/auth/recovery'
import AgreementPage from './pages/agreement'
import NotFoundPage from './pages/not-found'
import PrivacyPage from './pages/privacy'
import SubscriptionsPage from './pages/subscriptions'
import ProfilePage from './pages/profile'
import Watch from './pages/watch'
import AboutPage from './pages/about'
import RatesPage from './pages/rates'
import ContactsPage from './pages/contacts'
import PromotionPage from './pages/promotion'

const App = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { data, isLoading, isError, isSuccess, error } = useGetRatesQuery()
  const [
    getSubscriptions,
    {
      data: dataSubscriptions,
      isLoading: isLoadingSubscriptions,
      isError: isErrorSubscriptions,
      isSuccess: isSuccessSubscriptions,
      error: errorSubscriptions,
    },
  ] = useGetSubscriptionsMutation()

  const {
    data: userData,
    isError: isUserError,
    isLoading: isUserLoadaing,
    isSuccess: isUserSuccess,
    error: userError,
  } = useGetMeQuery()

  useEffect(() => {
    if (isUserSuccess) {
      getSubscriptions()
    } else {
      // notify('Для просмотра сериала нужно иметь аккаунт', 'info')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserSuccess])

  useEffect(() => {
    if (isSuccess) {
      dispatch(setRates(data))
    }
  }, [isSuccess])

  useEffect(() => {
    if (isErrorSubscriptions) {
      setSubs({ activeSubscription: null, subscriptions: [] })
      notify(
        `Ошибка получения подписок! ${errorSubscriptions?.data?.message || 'На стороне сервера'}`,
        'error',
      )
      return
    }
    if (isSuccessSubscriptions) {
      dispatch(setSubs(dataSubscriptions))
      return
    }
  }, [isSuccessSubscriptions])

  useEffect(() => {
    if (isUserError) {
      setUser({ user: null })
      // notify(`Ошибка авторизации! ${userError?.data?.message || 'На стороне сервера'}`, 'error')
    }
    if (isUserLoadaing) {
      setUser({ user: null })
      return
    }
    if (isUserSuccess) {
      dispatch(setUser({ user: userData }))
      return
    }
  }, [isUserLoadaing, isUserError, isUserSuccess])

  return (
    <Layout>
      <Routes>
        <Route
          path='/'
          element={
            <HomePage
              isLoading={isLoading}
              isError={isError}
            />
          }
        />
        <Route
          path='/home'
          element={
            <Navigate
              to='/'
              replace
            />
          }
        />
        <Route
          path='/auth'
          element={<Auth />}
        >
          <Route
            path='check'
            element={<CheckOTP />}
          />
          <Route
            path='login'
            element={<LoginPage />}
          />
          <Route
            path='register'
            element={<RegisterPage />}
          />
          <Route
            path='recovery'
            element={<RecoveryPage />}
          />
          {/* <Route
            path='change-password'
            element={<ChangePasswordPage />}
          /> */}
        </Route>
        <Route
          path='/privacy'
          element={<PrivacyPage />}
        />
        <Route element={<ProtectedRoute />}>
          <Route
            path='/me'
            element={<ProfilePage />}
          >
            <Route path='watch'>
              <Route
                path=':id'
                element={<VideoCard />}
              />
              <Route
                index
                element={<Watch />}
              />
            </Route>
            <Route
              path='subscriptions'
              element={<SubscriptionsPage />}
            />
            <Route
              index
              element={
                <Navigate
                  to={'subscriptions'}
                  replace
                />
              }
            />
          </Route>
        </Route>
        <Route
          path='/about'
          element={<AboutPage />}
        />
        <Route
          path='/promotion'
          element={<PromotionPage />}
        />
        <Route
          path='/rates'
          element={
            <RatesPage
              isLoading={isLoading}
              isError={isError}
            />
          }
        />
        <Route
          path='/agreement'
          element={<AgreementPage />}
        />
        <Route
          path='/contacts'
          element={<ContactsPage />}
        />
        <Route
          path='*'
          element={<NotFoundPage />}
        />
      </Routes>
    </Layout>
  )
}

export default App
