import { apiSlice } from '../../api/apiSlice'

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    auth: builder.mutation({
      query: (data) => ({
        url: '/auth',
        method: 'POST',
        body: data,
      }),
    }),
    checkRequestID: builder.query({
      query: (requestID) => ({
        url: `/auth/check-request?requestID=${requestID}`,
      }),
    }),
    getOtpCode: builder.mutation({
      query: (data) => ({
        url: '/auth/get-otp',
        method: 'POST',
        body: data,
      }),
    }),
    checkOtpCode: builder.mutation({
      query: (data) => ({
        url: '/auth/check-otp',
        method: 'POST',
        body: data,
      }),
    }),
    refreshOtpCode: builder.mutation({
      query: (data) => ({
        url: '/auth/refresh-otp',
        method: 'POST',
        body: data,
      }),
    }),
    login: builder.mutation({
      query: (data) => ({
        url: '/auth/login',
        method: 'POST',
        body: data,
      }),
    }),
    register: builder.mutation({
      query: (data) => ({
        url: '/auth/registration',
        method: 'POST',
        body: data,
      }),
    }),
    recoveryPassword: builder.mutation({
      query: (data) => ({
        url: '/auth/recovery',
        method: 'POST',
        body: data,
      }),
    }),
    registerConfirmation: builder.mutation({
      query: (data) => ({
        url: '/auth/registration/confirmation',
        method: 'POST',
        body: data,
      }),
    }),
    checkConfirmationRequest: builder.query({
      query: (requestID) => ({
        url: `/auth/registration/check-request?requestID=${requestID}`,
      }),
    }),
    changePassword: builder.mutation({
      query: (data) => ({
        url: `/auth/change-password/${data.requestID}`,
        method: 'POST',
        body: { password: data.password, passwordConfirmation: data.passwordConfirmation },
      }),
    }),
    logOut: builder.mutation({
      query: () => ({
        url: '/auth/logout',
        method: 'GET',
      }),
    }),
    getMe: builder.query({
      query: () => ({
        url: '/auth/me',
      }),
    }),
  }),
})

export const {
  useAuthMutation,
  useCheckRequestIDQuery,
  useGetOtpCodeMutation,
  useCheckOtpCodeMutation,
  useRefreshOtpCodeMutation,
  useLoginMutation,
  useRegisterMutation,
  useRegisterConfirmationMutation,
  useCheckConfirmationRequestQuery,
  useGetMeQuery,
  useLogOutMutation,
  useRecoveryPasswordMutation,
  useVerifyOtpMutation,
  useChangePasswordMutation,
} = authApiSlice
