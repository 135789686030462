import { useRef, useState, useEffect } from 'react'
import clsx from 'clsx'
import { Link, Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import InputMask from 'react-input-mask'
import { useForm } from 'react-hook-form'
import { notify } from 'utils/notify'

import { useAuthMutation } from 'store/slices/auth/authApiSlice'

import Button from 'components/ui/button'
import FormComponent from 'components/ui/form'

import styles from './auth.module.scss'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'store/slices/auth/authSlice'
import PhoneForm from './forms/phone'

const Auth = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const from = location?.state?.from || '/me'

  const userData = useSelector(selectCurrentUser)
  useEffect(() => {
    if (userData) {
      navigate(from, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  return (
    <div className={styles.auth}>
      <div className={styles.overlay}></div>
      <div className={clsx(styles.form_wrapper)}>
        {location.pathname === '/auth' ? <PhoneForm /> : <Outlet />}
      </div>
    </div>
  )
}
export default Auth
