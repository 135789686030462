import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  rates: [],
  counts: 0,
}

const rateSlice = createSlice({
  name: 'rate',
  initialState,
  reducers: {
    setRates: (state, action) => {
      state.rates = action.payload.rates
      state.counts = action.payload.counts
    },
  },
})

export const { setRates } = rateSlice.actions

export default rateSlice.reducer

export const selectRates = (state) => state.rate.rates
