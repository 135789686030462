import clsx from 'clsx'
import eyeIcon from '../../../assets/img/watched.svg'

import styles from './views.module.scss'

const Views = ({ counter, className }) => {
  return (
    <div className={clsx(styles.views, className)}>
      <img
        src={eyeIcon}
        alt='Кол-во просмотров'
      />
      <span>{counter}</span>
    </div>
  )
}

export default Views
