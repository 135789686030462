import { apiSlice } from '../../api/apiSlice'

export const subscriptionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createSubscription: builder.mutation({
      query: (data) => ({
        url: '/subscriptions',
        method: 'POST',
        body: data,
      }),
    }),
    getSubscriptions: builder.mutation({
      query: () => ({
        url: '/subscriptions',
        method: 'GET',
      }),
    }),
    cancelSubscription: builder.mutation({
      query: (data) => ({
        url: '/subscriptions/cancel',
        method: 'POST',
        body: data,
      }),
    }),
  }),
})

export const {
  useCreateSubscriptionMutation,
  useGetSubscriptionsMutation,
  useCancelSubscriptionMutation,
} = subscriptionApiSlice
