import { Link, Outlet } from 'react-router-dom'

import Sidebar from '../../components/ui/tabs'
import ProfileForm from '../../components/ui/profile-form'
import Tabs from '../../components/ui/tabs'

import styles from './profile.module.scss'

const ProfilePage = () => {
  return (
    <section className={styles.profile}>
      <div className={styles.container}>
        <ProfileForm />
        <div className={styles.content}>
          <Tabs />
          <Outlet />
        </div>
      </div>
    </section>
  )
}

export default ProfilePage
