import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'

import { useLoginMutation } from '../../../store/slices/auth/authApiSlice'
import { selectCurrentUser, setUser } from '../../../store/slices/auth/authSlice'
import { setSubs } from '../../../store/slices/subscription/subscriptionSlice'
import { notify } from '../../../utils/notify'

import Button from '../../../components/ui/button'
import FormComponent from '../../../components/ui/form'

import showPasswordIcon from '../../../assets/img/showPassword.svg'
import hidePasswordIcon from '../../../assets/img/hidePassword.svg'

import styles from '../auth.module.scss'

const LoginPage = () => {
  const {
    register,
    getValues,
    reset,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    defaultValues: {
      email: '',
      phone: '',
      name: '',
      password: '',
      passwordConfirmation: '',
    },
  })

  // ? API Login Mutation
  const [login, { data, isError, error, isSuccess, isLoading }] = useLoginMutation()

  const [showPassword, setShowPassword] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const onSubmit = async (data) => {
    try {
      const { email, password } = data
      let newEmail = email.toLowerCase().trim()
      let newPassword = password.trim()

      await login({
        email: newEmail,
        password: newPassword,
      }).unwrap()
    } catch (error) {
      console.log(error)
      const { data } = error
      notify(`${data?.message || 'Непредвиденная ошибка'}`, 'error')
      data?.errors?.map((err) =>
        setError(err.path, {
          message: err.msg,
        }),
      )
    }
  }

  useEffect(() => {
    if (isSuccess) {
      const { accessToken, user, activeSubscription, subscriptions } = data
      dispatch(setUser({ accessToken, user }))
      dispatch(setSubs({ activeSubscription, subscriptions }))
      localStorage.setItem('accessToken', accessToken)
      navigate('/me', { replace: true })
    }
    if (isError) {
      console.log(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isSuccess])

  return (
    <>
      <form
        className={clsx(styles.form)}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={clsx(styles.title)}>
          <h3>Авторизация</h3>
          <p>Введите свою почту и пароль</p>
        </div>
        <div className={clsx(styles.inputs)}>
          <div className={clsx(styles.form_control)}>
            <label htmlFor='email'>
              <span>
                Почта <b>*</b>
              </span>
              <input
                {...register('email', {
                  required: {
                    value: true,
                    message: 'Это поле обязательно',
                  },
                })}
                id='email'
                type='email'
                placeholder='user@email.com'
              />
              {errors['email'] && <span className={styles.error}>{errors['email'].message}</span>}
            </label>
          </div>
          <div className={clsx(styles.form_control, styles.form_control_icon)}>
            <label htmlFor='password'>
              <span>
                Пароль <b>*</b>
              </span>
              <input
                {...register('password', {
                  required: {
                    value: true,
                    message: 'Это поле обязательно',
                  },
                })}
                type={!showPassword ? 'password' : 'text'}
                placeholder='********'
              />
              {errors['password'] && (
                <span className={styles.error}>{errors['password'].message}</span>
              )}
            </label>
            <div
              className={styles.icon}
              onClick={() => setShowPassword(!showPassword)}
            >
              <img
                src={showPassword ? showPasswordIcon : hidePasswordIcon}
                alt='password'
              />
            </div>
          </div>
          <div className={clsx(styles.form_control, styles.submit)}>
            <Button
              disabled={false}
              loading={isLoading}
              type={'submit'}
              className='btn prime fw'
            >
              Войти
            </Button>
          </div>
        </div>
      </form>
      <div className={styles.footer}>
        <div className={styles.footer_item}>
          <p>
            <Link to={'/auth'}>Регистрация</Link>
          </p>
        </div>
        <div className={styles.footer_item}>
          <p>
            Забыли пароль? <Link to={'/auth/recovery'}>Восстановить</Link>
          </p>
        </div>
      </div>
    </>
  )
}

export default LoginPage
