import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { selectCurrentUser, setUser } from '../../../store/slices/auth/authSlice'
import { notify } from '../../../utils/notify'

import { useCreatePaymentMutation } from '../../../store/slices/payment/paymentApiSlice'
import { useCreateSubscriptionMutation } from '../../../store/slices/subscription/subscriptionApiSlice'

import Button from '../button'

import noticeIcon from '../../../assets/img/notice.svg'

import styles from './rate-card.module.scss'
import {
  selectActiveSubscription,
  setSubs,
} from '../../../store/slices/subscription/subscriptionSlice'
import { yaMetrikaGoals } from '../../../utils/yaMetrika'

const colors = ['blue', 'green', 'yellow', 'purple', 'red', 'dark']

const RateCard = ({
  title,
  subTitle,
  oldPrice,
  price,
  icon,
  adventages,
  notice,
  _id,
  period,
  type,
  show,
  colorId,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector(selectCurrentUser)
  const activeSubscription = useSelector(selectActiveSubscription)

  const [createPayment, { data, isError, error, isSuccess, isLoading }] = useCreatePaymentMutation()
  const [
    createSubscription,
    {
      data: dataSubscription,
      isError: isErrorSubscription,
      error: errorSubscription,
      isSuccess: isSuccessSubscription,
      isLoading: isLoadingSubscription,
    },
  ] = useCreateSubscriptionMutation()

  const handleBuyButton = async () => {
    await createPayment({ rateId: _id }).unwrap()
  }

  useEffect(() => {
    if (isError) {
      notify(error?.message, 'error')
      return
    }
    if (isSuccess) {
      notify(data?.message, 'success')
      const paymentData = data?.paymentData
      const widget = new window.YooMoneyCheckoutWidget({
        confirmation_token: paymentData?.confirmation?.confirmation_token,

        customization: {
          // payment_methods: ['qiwi'],
          modal: true,
        },
        error_callback: (err) => {
          console.log(err)
          //Обработка ошибок инициализации
        },
      })
      widget?.on('success', async () => {
        await createSubscriptionHandler(paymentData)
        widget.destroy()
      })
      widget?.on('fail', () => {
        notify('Платеж не прошел', 'error')
        widget?.destroy()
      })
      widget?.render()
    }
  }, [isError, isSuccess])

  const createSubscriptionHandler = async (paymentData) => {
    await createSubscription({
      paymentId: paymentData.metadata.InvoiceId,
      rateId: _id,
    }).unwrap()
  }

  useEffect(() => {
    if (isErrorSubscription) {
      notify(errorSubscription?.message, 'error')
      return
    }
    if (isSuccessSubscription) {
      notify(`Подписка "${title}" успешно оформлена`, 'success')
      dispatch(setSubs({ ...dataSubscription }))
      yaMetrikaGoals('buy')
      navigate(`/me`, { replace: true })
    }
  }, [isErrorSubscription, isSuccessSubscription])

  return show ? (
    <div className={clsx(styles.card, styles[`card__${colors[colorId]}`])}>
      <div className={clsx(styles.card_header, styles[colors[colorId]])}>
        <div className={styles.card_icon}>
          <img
            src={`${process.env.REACT_APP_API_URL}/api/v1/static/images/${icon}`}
            alt={title}
          />
        </div>
        <div className={styles.card_title}>
          <h4>{title}</h4>
          <p>{subTitle}</p>
        </div>
      </div>
      <div className={styles.card_body}>
        <div className={styles.card_adventages}>
          {adventages.length > 0 && (
            <ul className={styles.card_list}>
              {adventages.map((el, i) => (
                <li key={i}> {el}</li>
              ))}
            </ul>
          )}
        </div>
        <div className={styles.price}>
          <span>{oldPrice}</span>
          <span>{price} ₽</span>
          {period === 0 ? null : <span>/{period} дней</span>}
        </div>
        <div className={styles.card_notice}>
          <img
            src={noticeIcon}
            alt='Важно'
          />
          <p>{notice}</p>
        </div>
      </div>
      <div className={styles.card_footer}>
        <div className={styles.card_actions}>
          {user ? (
            activeSubscription?.rate === _id ? (
              <p>Активна</p>
            ) : (
              <Button
                onClick={handleBuyButton}
                className='btn second'
              >
                Оформить
              </Button>
            )
          ) : (
            <Link
              className='btn second'
              to={'/auth'}
            >
              Войти
            </Link>
          )}
          {/* <Button
            onClick={() => notify(`Покупка невозможна! Следите за информацией`, 'warning')}
            className='btn second'
          >
            Оформить
          </Button> */}
        </div>
      </div>
    </div>
  ) : null
}

export default RateCard
