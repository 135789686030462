import clsx from 'clsx'
import { useForm } from 'react-hook-form'
import { notify } from '../../../utils/notify'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { selectCurrentUser } from '../../../store/slices/auth/authSlice'
import Button from '../button'

import styles from './profile-form.module.scss'

const ProfileForm = () => {
  const user = useSelector(selectCurrentUser)
  const createdAt = new Date(user.createdAt)
  const dateOptions = {
    // weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }
  return (
    <div className={styles.wrapper}>
      <div className={clsx(styles.form)}>
        <div className={styles.avatar}>
          <img
            src={
              user?.avatar
                ? user.avatar
                : `${process.env.REACT_APP_API_URL}/api/v1/static/images/no-image.jpg`
            }
            alt=''
            className={styles.avatar_preview}
          />
        </div>
        <div className={styles.created}>
          <div className={styles.created_title}>Мой профиль</div>
          <div className={styles.created_date}>
            <span>Зарегистрирован:</span>
            <span>{createdAt.toLocaleDateString('ru-RU', dateOptions)}</span>
          </div>
        </div>
        <div className={clsx(styles.inputs)}>
          <div className={clsx(styles.form_control)}>
            <span>Имя</span>
            <div>{user.name}</div>
          </div>
          <div className={clsx(styles.form_control)}>
            <span>Почта</span>
            <div>{user.email}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ProfileForm
