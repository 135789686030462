import { useSelector } from 'react-redux'
import clsx from 'clsx'

import { selectRates } from '../../../store/slices/rate/rateSlice'

import RateCard from '../rate-card'
import Releases from '../releases'

import noticeIcon from '../../../assets/img/notice.svg'
import styles from './rates.module.scss'

const Rates = ({ isLoading, isError, className }) => {
  const rates = useSelector(selectRates)
  return (
    <>
      <Releases title='Даты выхода серий:' />
      <div className={clsx(styles.rates, className)}>
        {isLoading && 'Загрузка тарифов...'}
        {isError && 'При загрузке тарифов произошла ошибка...'}
        {rates.map((priceItem, index) => (
          <RateCard
            {...priceItem}
            key={index}
            colorId={index}
          />
        ))}
      </div>
    </>
  )
}

export default Rates
