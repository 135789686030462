import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import {
  selectActiveSubscription,
  selectSubscriptions,
  setSubs,
} from '../../store/slices/subscription/subscriptionSlice'
import { useCancelSubscriptionMutation } from '../../store/slices/subscription/subscriptionApiSlice'

import styles from './subscriptions.module.scss'
import clsx from 'clsx'
import Button from '../../components/ui/button'
import { notify } from '../../utils/notify'
import Countdown from '../../components/ui/countdown'
import Releases from '../../components/ui/releases'

const SubscriptionsPage = () => {
  const activeSubscription = useSelector(selectActiveSubscription)
  const subscriptions = useSelector(selectSubscriptions)

  const dispatch = useDispatch()

  const [cancelSubscription, { data, isSuccess, isError, error, isLoading }] =
    useCancelSubscriptionMutation()

  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  const cancelSubscriptionButtonHandler = async () => {
    let confirm = window.confirm('Вы уверены что хотите отменить поджписку?')
    if (confirm) {
      await cancelSubscription({ subscriptionId: activeSubscription._id }).unwrap()
    }
  }

  useEffect(() => {
    if (isError) {
      console.log(error)
    }

    if (isSuccess) {
      notify('Подписка отменена', 'success')
      dispatch(setSubs(data))
      return
    }
  }, [isError, isSuccess])

  return (
    <div className={styles.subs}>
      <div className={clsx(styles.active)}>
        {activeSubscription ? (
          <>
            <div className={styles.title}>
              <h4>У Вас есть активная подписка</h4>
            </div>
            <div className={styles.block}>
              <p className={styles.item_title}>"{activeSubscription.title}"</p>
              <p>
                {activeSubscription.type === 'limited' ? (
                  <div className={styles.actions}>
                    <span>
                      {new Date(activeSubscription.createdAt).toLocaleDateString(
                        'ru-RU',
                        dateOptions,
                      )}{' '}
                      -{' '}
                      {new Date(activeSubscription.timeEnd).toLocaleDateString(
                        'ru-RU',
                        dateOptions,
                      )}
                    </span>
                    <Button
                      onClick={cancelSubscriptionButtonHandler}
                      className={styles.cancel}
                    >
                      Отменить
                    </Button>
                  </div>
                ) : (
                  'Неограничено по времени'
                )}
              </p>
            </div>
          </>
        ) : (
          <>
            <div className={styles.title}>
              <h4>У Вас нет активной подписки</h4>
            </div>
            <div className={styles.block}>
              <Link
                className='btn prime'
                to='/rates'
              >
                оформить подписку
              </Link>
            </div>
          </>
        )}
      </div>
      <div className={styles.releases}>
        <Releases title={'Даты выхода серий'} />
      </div>
    </div>
  )
}
export default SubscriptionsPage
