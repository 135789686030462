import { createSlice } from '@reduxjs/toolkit'

const initialState = { activeSubscription: null, subscriptions: [] }

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSubs: (state, action) => {
      const { activeSubscription, subscriptions } = action.payload
      state.activeSubscription = activeSubscription
      state.subscriptions = subscriptions
    },
  },
})

export const { setSubs } = subscriptionSlice.actions

export default subscriptionSlice.reducer

export const selectActiveSubscription = (state) => state.subscription.activeSubscription
export const selectSubscriptions = (state) => state.subscription.subscriptions
