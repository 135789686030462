import React, { useState } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import FsLightbox from 'fslightbox-react'

import { selectRates } from '../../store/slices/rate/rateSlice'
import { selectCurrentUser } from '../../store/slices/auth/authSlice'

import { notify } from '../../utils/notify'

import Button from '../../components/ui/button'
import Countdown from '../../components/ui/countdown'

import bgVideo from '../../assets/videos/trailer.mp4'
import trailerPreview1 from '../../assets/img/covers/cover1.jpg'
import trailerPreview2 from '../../assets/img/covers/cover2.jpg'
import mobileBG from '../../assets/img/intro-mobile-bg.png'

import styles from './home.module.scss'
import Rates from '../../components/ui/rates'

const videosTrailers = [
  {
    preview: trailerPreview1,
    src: '/trailer1.mp4',
    title: 'Трейлер №1',
  },
  {
    preview: trailerPreview2,
    src: '/trailer2.mp4',
    title: 'Трейлер №2',
  },
]

const HomePage = ({ isLoading, isError }) => {
  const [lightboxControllerVideos, setLightboxControllerVideos] = useState({
    toggler: false,
    slide: 1,
  })
  const user = useSelector(selectCurrentUser)

  const openLightboxOnSlide = (slideIndex, slideTate, setSlideState) => {
    setSlideState({
      toggler: !slideTate.toggler,
      slide: slideIndex,
    })
  }

  const videoTrailersSrcArray = videosTrailers.map((el, i) => (
    <div
      className={styles.video_wrapper}
      style={{ width: '85%', height: 'auto' }}
    >
      <video
        autoPlay={i + 1 === lightboxControllerVideos.slide ? true : false}
        controls
        controlsList='nodownload'
      >
        <source
          src={el.src}
          type={'video/mp4'}
        />
      </video>
    </div>
  ))

  return (
    <>
      <section className={styles.intro}>
        <div className={styles.intro_bg}>
          <video
            autoPlay
            loop
            muted
            playsInline
            poster={trailerPreview1}
            className={styles.intro_bg_video}
          >
            <source
              src={bgVideo}
              type='video/mp4'
            ></source>
          </video>
          <div className={styles.intro_bg_img}>
            <img
              src={mobileBG}
              alt='background'
            />
          </div>
          <div className={styles.intro_overlay}></div>
        </div>
        <div className={styles.content}>
          <div className={styles.info}>
            <div className={styles.text}>
              <h1>
                <span>Герой</span>
                <br />
                (Народный Анимационный Фильм)
              </h1>
              <p>Кто они – настоящие герои?</p>
            </div>
            <div className={styles.actions}>
              {!user ? (
                <>
                  <Link
                    className={'btn prime'}
                    to='/auth'
                  >
                    <span>Хочу узнать</span>
                  </Link>
                </>
              ) : (
                <Link
                  className={'btn prime'}
                  to='/me'
                >
                  К просмотру
                </Link>
              )}
            </div>
          </div>
          <div className={styles.video}>
            {/* <iframe
              width='560'
              height='315'
              src='https://www.youtube.com/embed/zSSMsls7jfs?si=DpnLDyaX9G_IISw2'
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              allowFullScreen={true}
            ></iframe> */}

            {videosTrailers.map((trailer, i) => {
              return (
                <div
                  key={i}
                  className={clsx(styles.video_item)}
                  onClick={() =>
                    openLightboxOnSlide(
                      i + 1,
                      lightboxControllerVideos,
                      setLightboxControllerVideos,
                    )
                  }
                >
                  <div className={clsx(styles.video_item_img)}>
                    <img
                      src={trailer.preview}
                      alt={trailer.title}
                    />
                  </div>
                  <div className={styles.video_item_text}>
                    <p>{trailer.title}</p>
                  </div>
                  <div className={styles.video_item_play}>
                    <svg
                      width='60'
                      height='60'
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path d='M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C19.9939 15.5203 15.5203 19.9939 10 20ZM2 10.172C2.04732 14.5732 5.64111 18.1095 10.0425 18.086C14.444 18.0622 17.9995 14.4875 17.9995 10.086C17.9995 5.68451 14.444 2.10977 10.0425 2.086C5.64111 2.06246 2.04732 5.59876 2 10V10.172ZM8 14.5V5.5L14 10L8 14.5Z' />
                    </svg>
                  </div>
                </div>
              )
            })}
            <FsLightbox
              type='video'
              toggler={lightboxControllerVideos.toggler}
              slide={lightboxControllerVideos.slide}
              sources={videoTrailersSrcArray}
              // customAttributes={[
              //   {
              //     autoPlay: true,
              //     controls: true,
              //     controlsList: 'nodownload',
              //   },
              // ]}
            />
          </div>
          <div className={styles.countdown}>
            <Countdown
              notice='Успейте оформить подписку до начала премьеры и получите 50% скидку'
              time='Sep 20 2024 21:00:00'
            />
          </div>
        </div>
      </section>
      <section className={styles.rates}>
        <div className={styles.rates_title}>
          <h2>
            Тарифы и <span>Цены</span>
          </h2>
          <p>
            В этом разделе находится информация по доступным тарифам и ценам за доступ к просмотру
            сериала "Герой". Пожалуйста внимательно ознакомьтесь с условиями офромления подписки, а
            так же посмотрите <Link to={'/agreement'}>условия пользовательского соглашения</Link>
          </p>
        </div>
        <Rates
          isLoading={isLoading}
          isError={isError}
          className={styles.rates_grid}
        />
      </section>
    </>
  )
}

export default HomePage
