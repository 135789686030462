import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Outlet, useLocation, useParams } from 'react-router-dom'
import clsx from 'clsx'
import axios from 'axios'

import { selectAvailebleVideos } from '../../store/slices/auth/authSlice'
import { useGetVideosQuery } from '../../store/slices/video/videoApiSlice'
import { setVideos } from '../../store/slices/video/videoSlice'

import { notify } from '../../utils/notify'

import Card from '../../components/ui/card'

import styles from './watch.module.scss'

const Watch = () => {
  const location = useLocation()
  const { data, isLoading, isSuccess, isError, error } = useGetVideosQuery()
  const dispatch = useDispatch()

  useEffect(() => {
    if (data) {
      dispatch(setVideos(data))
      return
    }
  }, [data, isLoading, isSuccess])

  return (
    <div className={styles.watch}>
      <div className={styles.title}>1 Сезон</div>
      <div className={styles.content}>
        {isLoading && 'Загрузка...'}
        {isError && 'При загрузке произошла ошибка!'}
        {isSuccess && data && (
          <div className={styles.items}>
            {data?.videos?.map((card, i) => (
              <Card
                {...card}
                key={i}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default Watch
