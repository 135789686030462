import React from 'react'
import clsx from 'clsx'

import styles from './button.module.scss'

const Button = ({
  children,
  type = 'button',
  onClick,
  className,
  icon = '',
  disabled = false,
  loading = false,
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled || loading}
      className={clsx(className)}
    >
      {loading ? (
        <svg
          width={'40px'}
          height={'15px'}
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 200 200'
        >
          <circle
            fill='#ffffff'
            stroke='#000000'
            strokeWidth='2'
            r='20'
            cx='40'
            cy='100'
          >
            <animate
              attributeName='opacity'
              calcMode='spline'
              dur='1.6'
              values='1;0;1;'
              keySplines='.5 0 .5 1;.5 0 .5 1'
              repeatCount='indefinite'
              begin='-.4'
            ></animate>
          </circle>
          <circle
            fill='#ffffff'
            stroke='#000000'
            strokeWidth='2'
            r='20'
            cx='100'
            cy='100'
          >
            <animate
              attributeName='opacity'
              calcMode='spline'
              dur='1.6'
              values='1;0;1;'
              keySplines='.5 0 .5 1;.5 0 .5 1'
              repeatCount='indefinite'
              begin='-.2'
            ></animate>
          </circle>
          <circle
            fill='#ffffff'
            stroke='#000000'
            strokeWidth='2'
            r='20'
            cx='160'
            cy='100'
          >
            <animate
              attributeName='opacity'
              calcMode='spline'
              dur='1.6'
              values='1;0;1;'
              keySplines='.5 0 .5 1;.5 0 .5 1'
              repeatCount='indefinite'
              begin='0'
            ></animate>
          </circle>
        </svg>
      ) : (
        <>
          {children}
          {icon && (
            <img
              src={icon}
              alt='icon'
            />
          )}
        </>
      )}
    </button>
  )
}

export default Button
