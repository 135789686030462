import { configureStore } from '@reduxjs/toolkit'

import { apiSlice } from './api/apiSlice'
import authReducer from './slices/auth/authSlice'
import videoReducer from './slices/video/videoSlice'
import rateReducer from './slices/rate/rateSlice'
import subscriptionReducer from './slices/subscription/subscriptionSlice'

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    video: videoReducer,
    rate: rateReducer,
    subscription: subscriptionReducer,
  },
  // devTools: false,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([apiSlice.middleware]),
})
