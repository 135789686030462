import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import axios from 'axios'

import { notify } from '../../utils/notify'
import { vkPixelGoals } from '../../utils/vkPixelGoals'

import Button from '../../components/ui/button'

import styles from './contacts.module.scss'
import { Link } from 'react-router-dom'

const ContactsPage = () => {
  const {
    register,
    reset,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting, isValid, isLoading },
  } = useForm()

  const refForm = useRef(null)

  const onSubmit = async (formData) => {
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/mail`, formData)
      notify(data?.message || 'Успешно!', 'success')
      vkPixelGoals('reachGoal', 3461659, 'SendContactData')
      reset()
    } catch (error) {
      notify(error?.response?.data?.message || 'Ошибка!', 'error')
    }
  }
  return (
    <section className={styles.contacts}>
      <div className={styles.content}>
        <div className={styles.form_wrapper}>
          <form
            className={clsx(styles.form)}
            onSubmit={handleSubmit(onSubmit)}
            ref={refForm}
          >
            <div className={clsx(styles.title)}>
              <h3>Форма обратной связи</h3>
              <p>Все поля являются обязательными</p>
            </div>
            <div className={clsx(styles.inputs)}>
              <div className={clsx(styles.form_control)}>
                <>
                  <input
                    {...register('name', {
                      required: {
                        value: true,
                        message: 'Это поле обязательно',
                      },
                      minLength: {
                        value: 2,
                        message: 'Минимум 2 символа',
                      },
                      maxLength: {
                        value: 16,
                        message: 'Максимум 16 символов',
                      },
                      // pattern: "",
                    })}
                    type='text'
                    placeholder='Имя'
                  />
                  {errors['name'] && <span className={styles.error}>{errors['name'].message}</span>}
                </>
              </div>
              <div className={clsx(styles.form_control)}>
                <>
                  <input
                    {...register('email', {
                      required: {
                        value: true,
                        message: 'Это поле обязательно',
                      },
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Некорректная почта',
                      },
                    })}
                    type='text'
                    placeholder='Email'
                  />
                  {errors['email'] && (
                    <span className={styles.error}>{errors['email'].message}</span>
                  )}
                </>
              </div>
              <div className={clsx(styles.form_control)}>
                <>
                  <textarea
                    {...register('message', {
                      required: {
                        value: true,
                        message: 'Это поле обязательно',
                      },
                      minLength: {
                        value: 2,
                        message: 'Минимум 2 символа',
                      },
                      maxLength: {
                        value: 300,
                        message: 'Максимум 300 символов',
                      },
                    })}
                    type='text'
                    placeholder='Сообщение'
                  />
                  {errors['message'] && (
                    <span className={styles.error}>{errors['message'].message}</span>
                  )}
                </>
              </div>
              <div className={clsx(styles.form_control, styles.submit)}>
                <Button
                  className='btn prime'
                  disabled={false}
                  loading={isSubmitting}
                  type={'submit'}
                  // onClick={handleSubmitButton}
                >
                  Отправить
                </Button>
              </div>
            </div>
          </form>
          <div className={styles.form_footer}>
            <p>
              Нажимая "Отправить" Вы соглашаетесь с{' '}
              <Link to={'/privacy'}>
                политикой конфиденциальности и првилами обработки персональных данных
              </Link>
            </p>
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.title}>
            <h2>Контактная информация</h2>
            <p>
              По вопросам сотрудничества или обслуживания вы можете обращаться к нам при помощи
              следующих контактных данных:
            </p>
          </div>
          <div className={styles.actions}>
            <a href='mailto:info@movie-hero.com'>info@movie-hero.com</a>
            <a href='tel:+79266385055'>+7 (926) 638-50-55</a>
          </div>
          <div className={styles.jur}>
            <p>
              г. Москва, Муниципальный округ Пресненский, наб. Пресненская, д. 8, стр. 1, помещ.
              484С
            </p>
            <p>ООО «КАДР»</p>
            <p>ИНН 9703175473</p>
            <p>ОГРН 1247700205400</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactsPage
