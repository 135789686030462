import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { selectCurrentUser } from '../../../store/slices/auth/authSlice'
import { links } from '../../../utils/navLinks'

import Logo from '../../ui/logo'
import ProfileIcon from '../../ui/profile-icon'

import styles from './header.module.scss'

const Header = () => {
  const { pathname } = useLocation()
  const user = useSelector(selectCurrentUser)
  return (
    <header className={styles.header}>
      <Logo variant='dark' />
      {user ? (
        <ProfileIcon />
      ) : (
        <Link
          className='btn prime rounded'
          to={'/auth'}
        >
          Войти
        </Link>
      )}
    </header>
  )
}

export default Header
