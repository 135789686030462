import clsx from 'clsx'

// Intro images
import gunImg from '../../assets/img/promo/gun.png'
import logoSvg from '../../assets/img/logo.svg'
import logoKadr from '../../assets/img/promo/logo-kadr.png'
import logoAP from '../../assets/img/promo/logo-ap.png'

// Anotice Images
import ggPose1 from '../../assets/img/promo/gg-pose-1.png'

// Anotice 2 Images
import g1Pose1 from '../../assets/img/promo/g1-pose-1.png'

// Look a Like
import lookALikeBg from '../../assets/img/promo/bg-3.png'

// Characters
import ggPose2 from '../../assets/img/promo/gg-pose-2.png'
import g2Pose1 from '../../assets/img/promo/g2-pose-1.png'
import g3Pose1 from '../../assets/img/promo/g3-pose-1.png'
import menImg1 from '../../assets/img/promo/men-1.jpg'
import menImg2 from '../../assets/img/promo/men-2.jpg'
import menImg3 from '../../assets/img/promo/management-1.png'

// About
import men1Poster1 from '../../assets/img/promo/men-1-poster-1.jpg'
import men1Poster2 from '../../assets/img/promo/men-1-poster-2.jpg'
import men1Poster3 from '../../assets/img/promo/men-1-poster-3.jpg'
import men1Poster4 from '../../assets/img/promo/men-1-poster-4.jpg'
import men1Poster5 from '../../assets/img/promo/men-1-poster-5.jpg'
import men1Poster6 from '../../assets/img/promo/men-1-poster-6.jpg'
import men1Poster7 from '../../assets/img/promo/men-1-poster-7.jpg'

import men2Poster1 from '../../assets/img/promo/men-2-poster-1.jpg'
import men2Poster2 from '../../assets/img/promo/men-2-poster-2.jpg'
import men2Poster3 from '../../assets/img/promo/men-2-poster-3.jpg'
import men2Poster4 from '../../assets/img/promo/men-2-poster-4.jpg'
import men2Poster5 from '../../assets/img/promo/men-2-poster-5.jpg'
import men2Poster6 from '../../assets/img/promo/men-2-poster-6.jpg'
import men2Poster7 from '../../assets/img/promo/men-2-poster-7.jpg'

import men3Poster1 from '../../assets/img/promo/men-3-poster-1.jpg'
import men3Poster2 from '../../assets/img/promo/men-3-poster-2.jpg'
import men3Poster3 from '../../assets/img/promo/men-3-poster-3.jpg'
import men3Poster4 from '../../assets/img/promo/men-3-poster-4.jpg'
import men3Poster5 from '../../assets/img/promo/men-3-poster-5.jpg'
import men3Poster6 from '../../assets/img/promo/men-3-poster-6.jpg'
import men3Poster7 from '../../assets/img/promo/men-3-poster-7.jpg'

import styles from './promotion.module.scss'

const BgLogo = ({ text = 'Криминальная комедия', className, sm = false }) => {
  return (
    <div className={clsx(className, styles.bg_logo, sm && styles.bg_logo_sm)}>
      <div className={clsx(styles.bg_logo_adult, sm && styles.bg_logo_adult_sm)}>18+</div>
      <img
        src={logoSvg}
        alt='Герой'
      />
      <div className={clsx(styles.bg_logo_text, sm && styles.bg_logo_text_sm)}>{text}</div>
    </div>
  )
}

const PromotionPage = () => {
  return (
    <section className={styles.promo}>
      <div className={styles.content}>
        <div className={clsx(styles.block, styles.intro)}>
          <div className={styles.intro_left}>
            <div className={styles.intro_left_text}>Криминальная комедия</div>
            <div className={styles.intro_left_img}>
              <img
                src={gunImg}
                alt='Криминальная комедия'
              />
              <div className={styles.intro_adult}>18+</div>
            </div>
          </div>
          <div className={styles.intro_right}>
            <div className={styles.intro_right_img}>
              <img
                src={logoSvg}
                alt='Герой'
              />
            </div>
            <div className={styles.intro_right_bottom}>
              <div className={styles.intro_right_text}>
                5 Серий <br /> По 12 Минут
              </div>
              <div className={styles.logos}>
                <div className={styles.logo}>
                  <img
                    src={logoAP}
                    alt='AP Entertainment'
                  />
                </div>
                <div className={styles.logo}>
                  <img
                    src={logoKadr}
                    alt='Кадр'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={clsx(styles.block, styles.anotice)}>
          <div className={styles.anotice_bg}>
            <img
              src={ggPose1}
              alt='Главный герой'
            />
          </div>
          <div className={styles.anotice_content}>
            <BgLogo />
            <div className={styles.anotice_title}>
              <h4>Оригинальный анимационный сериал</h4>
            </div>
            <div className={styles.anotice_text}>
              <p>
                В провинциальном городе местные бандиты узнав тайны высокопоставленных чиновников,
                затевают собственную игру, в которую оказывается втянут весь город
              </p>
            </div>
          </div>
        </div>
        <div className={clsx(styles.block, styles.look_a_like)}>
          <img
            src={lookALikeBg}
            alt=''
          />
        </div>
        <div className={clsx(styles.block, styles.anotice2)}>
          <div className={styles.anotice2_bg}>
            <BgLogo />
            <div className={styles.anotice2_img}>
              <img
                src={g1Pose1}
                alt=''
              />
            </div>
          </div>
          <div className={styles.anotice2_content}>
            <div className={styles.anotice2_text}>
              <p>
                Череда нелепых и ироничных совпадений с неожиданными поворотами, в которых главные
                персонажи сталкиваются со своими врагами, спасают девушку и попадают в перестрелки
              </p>
            </div>
          </div>
        </div>
        <div className={clsx(styles.block, styles.character)}>
          <div className={styles.character_img}>
            <img
              src={ggPose2}
              alt=''
            />
          </div>
          <div className={styles.character_top}>
            <BgLogo sm />
            <div className={styles.logos}>
              <div className={styles.logo}>
                <img
                  src={logoAP}
                  alt='AP Entertainment'
                />
              </div>
              <div className={styles.logo}>
                <img
                  src={logoKadr}
                  alt='Кадр'
                />
              </div>
            </div>
          </div>
          <div className={styles.character_bottom}>
            <div className={styles.character_voice}>
              <p>Озвучивает</p>
              <h4>Егор Климович</h4>
            </div>
            <div className={styles.character_name}>
              <h3>Юрич</h3>
              <p>Главный герой.</p>
              <p>Харизматичный лидер</p>
              <p>Жестокий, но справедливый</p>
            </div>
          </div>
        </div>
        <div className={clsx(styles.block, styles.about)}>
          <div className={styles.about_content}>
            <div className={styles.about_text}>
              <div className={styles.about_img}>
                <img
                  src={menImg1}
                  alt=''
                />
              </div>
              <div className={styles.about_name}>
                <h3>Егор Климович</h3>
                <p>Актер и режиссер</p>
              </div>
              <div className={styles.about_description}>
                <p>Учавствовал в проектах:</p>
                <p>Движение вверх, Стая, Точка опоры, Предательство, Время побеждать и других</p>
              </div>
            </div>
            <div className={styles.about_movies}>
              <div className={styles.about_column}>
                <div className={styles.about_column_item}>
                  <img
                    src={men1Poster2}
                    alt=''
                  />
                </div>
                <div className={styles.about_column_item}>
                  <img
                    src={men1Poster1}
                    alt=''
                  />
                </div>
                <div className={styles.about_column_item}>
                  <img
                    src={men1Poster3}
                    alt=''
                  />
                </div>
              </div>
              <div className={styles.about_column}>
                <div className={styles.about_column_item}>
                  <img
                    src={men1Poster4}
                    alt=''
                  />
                </div>
                <div className={styles.about_column_item}>
                  <img
                    src={men1Poster5}
                    alt=''
                  />
                </div>
                <div className={styles.about_column_item}>
                  <img
                    src={men1Poster6}
                    alt=''
                  />
                </div>
                <div className={styles.about_column_item}>
                  <img
                    src={men1Poster7}
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={clsx(styles.block, styles.character)}>
          <div className={styles.character_img}>
            <img
              src={g2Pose1}
              alt=''
            />
          </div>
          <div className={styles.character_top}>
            <BgLogo sm />
            <div className={styles.logos}>
              <div className={styles.logo}>
                <img
                  src={logoAP}
                  alt='AP Entertainment'
                />
              </div>
              <div className={styles.logo}>
                <img
                  src={logoKadr}
                  alt='Кадр'
                />
              </div>
            </div>
          </div>
          <div className={styles.character_bottom}>
            <div className={styles.character_voice}>
              <p>Озвучивает</p>
              <h4>Ольга Макеева</h4>
            </div>
            <div className={styles.character_name}>
              <h3>Вика</h3>
              <p>Находчивая и красивая героиня</p>
            </div>
          </div>
        </div>
        <div className={clsx(styles.block, styles.about)}>
          <div className={styles.about_content}>
            <div className={styles.about_text}>
              <div className={styles.about_img}>
                <img
                  src={menImg2}
                  alt=''
                />
              </div>
              <div className={styles.about_name}>
                <h3>Ольга Макеева</h3>
                <p>Актриса театра и кино</p>
              </div>
              <div className={styles.about_description}>
                <p>Учавствовала в фильмах и проектах:</p>
                <p>
                  Содержанки 4, Пациент №1, Зона вселенной, Начальник разведки, Чернобыль и другие
                </p>
              </div>
            </div>
            <div className={styles.about_movies}>
              <div className={styles.about_column}>
                <div className={styles.about_column_item}>
                  <img
                    src={men2Poster1}
                    alt=''
                  />
                </div>
                <div className={styles.about_column_item}>
                  <img
                    src={men2Poster2}
                    alt=''
                  />
                </div>
                <div className={styles.about_column_item}>
                  <img
                    src={men2Poster3}
                    alt=''
                  />
                </div>
              </div>
              <div className={styles.about_column}>
                <div className={styles.about_column_item}>
                  <img
                    src={men2Poster4}
                    alt=''
                  />
                </div>
                <div className={styles.about_column_item}>
                  <img
                    src={men2Poster5}
                    alt=''
                  />
                </div>
                <div className={styles.about_column_item}>
                  <img
                    src={men2Poster6}
                    alt=''
                  />
                </div>
                <div className={styles.about_column_item}>
                  <img
                    src={men2Poster7}
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={clsx(styles.block, styles.character)}>
          <div className={styles.character_img}>
            <img
              src={g3Pose1}
              alt=''
            />
          </div>
          <div className={styles.character_top}>
            <BgLogo sm />
            <div className={styles.logos}>
              <div className={styles.logo}>
                <img
                  src={logoAP}
                  alt='AP Entertainment'
                />
              </div>
              <div className={styles.logo}>
                <img
                  src={logoKadr}
                  alt='Кадр'
                />
              </div>
            </div>
          </div>
          <div className={styles.character_bottom}>
            <div className={styles.character_voice}>
              <p>Сценарист</p>
              <h4>Александр Красовский</h4>
            </div>
            <div className={styles.character_name}>
              <p>Продюссеры</p>
              <h3>Александр Красовский</h3>
              <h3>Дмитрий Рудовский</h3>
            </div>
          </div>
        </div>
        <div className={clsx(styles.block, styles.about)}>
          <div className={styles.about_content}>
            <div className={styles.about_text}>
              <div className={styles.about_img}>
                <img
                  src={menImg3}
                  alt=''
                />
              </div>
              <div className={styles.about_name}>
                <h3>Дмитрий Рудовский</h3>
                <p>Продюссер, сценарист</p>
              </div>
              <div className={styles.about_description}>
                <p>Среди полнометражных продюссерских работ:</p>
                <p>«Девятая Рота», «Жара», «ДухLess2», Сталинград, «БатальонЪ», «Притяжение»</p>
              </div>
            </div>
            <div className={styles.about_movies}>
              <div className={styles.about_column}>
                <div className={styles.about_column_item}>
                  <img
                    src={men3Poster1}
                    alt=''
                  />
                </div>
                <div className={styles.about_column_item}>
                  <img
                    src={men3Poster2}
                    alt=''
                  />
                </div>
                <div className={styles.about_column_item}>
                  <img
                    src={men3Poster3}
                    alt=''
                  />
                </div>
              </div>
              <div className={styles.about_column}>
                <div className={styles.about_column_item}>
                  <img
                    src={men3Poster4}
                    alt=''
                  />
                </div>
                <div className={styles.about_column_item}>
                  <img
                    src={men3Poster5}
                    alt=''
                  />
                </div>
                <div className={styles.about_column_item}>
                  <img
                    src={men3Poster6}
                    alt=''
                  />
                </div>
                <div className={styles.about_column_item}>
                  <img
                    src={men3Poster7}
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default PromotionPage
