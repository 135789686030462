import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  videos: [],
  currentVideo: null,
  counts: 0,
}

const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    setVideos: (state, action) => {
      state.videos = action.payload.videos
      state.counts = action.payload.counts
    },
    setCurrentVideo: (state, action) => {
      state.currentVideo = action.payload
    },
  },
})

export const { setVideos, setCurrentVideo } = videoSlice.actions

export default videoSlice.reducer

export const selectVideos = (state) => state.video.videos
export const selectCurrentVideo = (state) => state.video.currentVideo
