import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'

import { selectCurrentUser } from '../../../store/slices/auth/authSlice'

import profileInfoIcon from '../../../assets/img/profile-info.svg'
import playIcon from '../../../assets/img/play.svg'

import styles from './tabs.module.scss'

const Tabs = () => {
  return (
    <div className={styles.tabs}>
      <NavLink
        className={({ isActive }) => clsx(styles.tab, isActive && styles.active)}
        to='subscriptions'
        end
      >
        Моя подписка
      </NavLink>
      <NavLink
        className={({ isActive }) => clsx(styles.tab, isActive && styles.active)}
        to='watch'
      >
        Смотреть сериал
      </NavLink>
    </div>
  )
}

export default Tabs
