import { apiSlice } from '../../api/apiSlice'

export const rateApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRates: builder.query({
      query: () => `/rates`,
    }),
  }),
})

export const { useGetRatesQuery } = rateApiSlice
