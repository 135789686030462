import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, Link, useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import InputMask from 'react-input-mask'

import {
  useCheckRequestIDQuery,
  useRegisterMutation,
} from '../../../store/slices/auth/authApiSlice'
import { selectCurrentUser, setUser } from '../../../store/slices/auth/authSlice'

import { notify } from '../../../utils/notify'
import { vkPixelGoals } from '../../../utils/vkPixelGoals'

import FormComponent from '../../../components/ui/form'
import Button from '../../../components/ui/button'

import showPasswordIcon from '../../../assets/img/showPassword.svg'
import hidePasswordIcon from '../../../assets/img/hidePassword.svg'

import styles from '../auth.module.scss'
import { setSubs } from 'store/slices/subscription/subscriptionSlice'

const RegisterPage = () => {
  // Прверяем ссылку и и выводим соответствующую разметку
  const [searchParams, setSearchParams] = useSearchParams()
  const requestID = searchParams.get('requestID')
  const { data, isLoading, isError, isSuccess, error } = useCheckRequestIDQuery(requestID)

  const {
    register,
    getValues,
    reset,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    defaultValues: {
      email: '',
      phone: '',
      name: '',
      password: '',
      passwordConfirmation: '',
    },
  })
  const [
    registration,
    { data: userData, isError: isUserError, isSuccess: isUserSuccess, isLoading: isUserLoading },
  ] = useRegisterMutation()

  const [showPassword, setShowPassword] = useState(false)
  const [disableField, setDisableField] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const onSubmit = async (data) => {
    try {
      const { phone, email, name, password, passwordConfirmation } = data
      let newPhone = phone.replaceAll(/[+\-_()\s]/g, '')
      let newEmail = email.toLowerCase().trim()
      let newName = name.trim()
      let newPassword = password.trim()
      let newPasswordConfirmation = passwordConfirmation.trim()

      await registration({
        phone: newPhone,
        email: newEmail,
        name: newName,
        password: newPassword,
        passwordConfirmation: newPasswordConfirmation,
        requestID,
      }).unwrap()
    } catch (error) {
      console.log(error)
      const { data } = error
      notify(`${data?.message || 'Непредвиденная ошибка'}`, 'error')
      data?.errors?.map((err) =>
        setError(err.path, {
          message: err.msg,
        }),
      )
    }
  }

  useEffect(() => {
    if (isSuccess) {
      const { authMethod, authType, message } = data
      if (authMethod === 'email') {
        setValue('email', message)
        setDisableField('email')
      }
      if (authMethod === 'phone') {
        setValue('phone', message)
        setDisableField('phone')
      }
    }
  }, [isSuccess])

  useEffect(() => {
    if (isUserSuccess) {
      const { accessToken, user, activeSubscription, subscriptions } = userData
      dispatch(setUser({ accessToken, user }))
      dispatch(setSubs({ activeSubscription, subscriptions }))
      localStorage.setItem('accessToken', accessToken)
      notify(`Регистрация завершена`, 'success')
      navigate('/me', { replace: true })
    }
    if (isUserError) {
      console.log(error)
      // notify(`${error?.data?.message || 'Непредвиденная ошибка'}`, 'error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserError, isUserSuccess])

  return (
    <>
      {isLoading && (
        <div className={styles.form}>
          <div className={clsx(styles.title, styles.title_otp)}>
            <h3>Загрузка...</h3>
            <p>{error?.data?.message}</p>
          </div>
        </div>
      )}
      {isError && (
        <div className={styles.form}>
          <div className={clsx(styles.title, styles.title_otp)}>
            <h3>Произошла ошибка</h3>
            <p>{error?.data?.message}</p>
          </div>
        </div>
      )}
      {isSuccess && data && (
        <form
          className={clsx(styles.form)}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={clsx(styles.title)}>
            <h3>Еще чуть-чуть</h3>
            <p>Заполните обязательные поля. Это нужно для надежной защиты вашего аккаунта</p>
          </div>
          <div className={clsx(styles.inputs)}>
            <div className={clsx(styles.form_control)}>
              <label htmlFor='email'>
                <span>
                  Почта <b>*</b>
                </span>
                <input
                  {...register('email', {
                    required: {
                      value: true,
                      message: 'Это поле обязательно',
                    },
                  })}
                  disabled={disableField === 'email'}
                  id='email'
                  type='email'
                  placeholder='user@email.com'
                />
                {errors['email'] && <span className={styles.error}>{errors['email'].message}</span>}
              </label>
            </div>
            <div className={clsx(styles.form_control)}>
              <label htmlFor='phone'>
                <span>
                  Телефон <b>*</b>
                </span>
                <InputMask
                  {...register('phone', {
                    required: {
                      value: true,
                      message: 'Это поле обязательно',
                    },
                  })}
                  disabled={disableField === 'phone'}
                  mask='+7 (999) 999-99-99'
                  id='phone'
                  placeholder='+7 (___) ___-__-__'
                />
                {errors['phone'] && <span className={styles.error}>{errors['phone'].message}</span>}
              </label>
            </div>
            <div className={clsx(styles.form_control)}>
              <label htmlFor='name'>
                <span>
                  Имя <b>*</b>
                </span>
                <input
                  {...register('name', {
                    required: {
                      value: true,
                      message: 'Это поле обязательно',
                    },
                  })}
                  type='name'
                  placeholder='Иван'
                />
                {errors['name'] && <span className={styles.error}>{errors['name'].message}</span>}
              </label>
            </div>
            <div
              className={clsx(
                styles.form_control,
                styles.form_control_password,
                styles.form_control_icon,
              )}
            >
              <label htmlFor='password'>
                <span>
                  Пароль <b>*</b>
                </span>
                <input
                  {...register('password', {
                    required: {
                      value: true,
                      message: 'Это поле обязательно',
                    },
                  })}
                  type={!showPassword ? 'password' : 'text'}
                  placeholder='********'
                />
                {errors['password'] && (
                  <span className={styles.error}>{errors['password'].message}</span>
                )}
              </label>
              <div
                className={styles.icon}
                onClick={() => setShowPassword(!showPassword)}
              >
                <img
                  src={showPassword ? showPasswordIcon : hidePasswordIcon}
                  alt='password'
                />
              </div>
            </div>
            <div className={clsx(styles.form_control, styles.form_control_password)}>
              <label htmlFor='passwordConfirmation'>
                <span>
                  Пароль <b>*</b>
                </span>
                <input
                  {...register('passwordConfirmation', {
                    required: {
                      value: true,
                      message: 'Это поле обязательно',
                    },
                  })}
                  type={!showPassword ? 'password' : 'text'}
                  placeholder='********'
                />
                {errors['passwordConfirmation'] && (
                  <span className={styles.error}>{errors['passwordConfirmation'].message}</span>
                )}
              </label>
            </div>
            <div className={clsx(styles.form_control, styles.form_control_checkbox)}>
              <label htmlFor='getNews'>
                <input
                  {...register('getNews', {})}
                  id='getNews'
                  type='checkbox'
                />
                <span>Получать новости</span>
              </label>
            </div>
            <div className={clsx(styles.form_control, styles.submit)}>
              <Button
                disabled={false}
                loading={isLoading}
                type={'submit'}
                className='btn prime fw'
              >
                Завершить
              </Button>
            </div>
          </div>
        </form>
      )}
      <div className={styles.footer}>
        <div className={styles.footer_item}>
          <p>
            <Link to={'/auth'}>{`<-`}Вернуться</Link>
          </p>
        </div>
        <div className={styles.footer_item}>
          <p>
            <Link to={'/auth/login'}>Войти по логину и паролю</Link>
          </p>
        </div>
      </div>
    </>
  )
}

export default RegisterPage
