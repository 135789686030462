import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import InputMask from 'react-input-mask'

import { useAuthMutation } from 'store/slices/auth/authApiSlice'
import { notify } from 'utils/notify'
import Button from 'components/ui/button'

import styles from '../auth.module.scss'

const PhoneForm = () => {
  const navigate = useNavigate()
  const {
    register,
    getValues,
    reset,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isSubmitting, isValid, isLoading },
  } = useForm({
    defaultValues: {
      phone: '',
    },
  })
  const [activeField, setActiveField] = useState('phone')

  const changeActiveField = (field) => {
    setValue(activeField, '')
    setActiveField(field)
  }

  const [auth, { data: authData, isLoading: isAuthLoading, isError: isAuthError }] =
    useAuthMutation()

  const onSubmit = async (data) => {
    try {
      const { phone, email } = data
      if (phone) {
        let newPhone = phone.replaceAll(/[+\-_()\s]/g, '')
        await auth({ phone: newPhone }).unwrap()
      }
      if (email) {
        const newEmail = email.toLowerCase().trim()
        await auth({ email: newEmail }).unwrap()
      }
    } catch (error) {
      console.log(error)
      const { data } = error
      notify(`${data?.message || 'Непредвиденная ошибка'}`, 'error')
      data?.errors?.map((err) =>
        setError(err.path, {
          message: err.msg,
        }),
      )
    }
  }

  useEffect(() => {
    if (authData) {
      const { requestID } = authData
      navigate(`/auth/check?requestID=${requestID}`)
    }
  }, [authData])

  return (
    <>
      <form
        className={clsx(styles.form)}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={clsx(styles.title)}>
          <h3>Войти или Зарегистрироваться</h3>
          <p>Введите телефон или почту</p>
        </div>
        <div className={clsx(styles.inputs)}>
          <div className={clsx(styles.form_control, styles.form_control_icon)}>
            {activeField === 'phone' ? (
              <>
                <label htmlFor='phoneID'>
                  <span>
                    Телефон <b>*</b>
                  </span>
                  <InputMask
                    {...register('phone', {
                      required: {
                        value: true,
                        message: 'Это поле обязательно',
                      },
                    })}
                    mask='+7 (999) 999-99-99'
                    // maskPlaceholder={null}
                    id='phoneID'
                    placeholder='+7 (___) ___-__-__'
                  />
                  {errors['phone'] && (
                    <span className={styles.error}>{errors['phone'].message}</span>
                  )}
                </label>
                <div
                  className={styles.icon}
                  onClick={() => changeActiveField('email')}
                >
                  <svg
                    width='70'
                    height='70'
                    viewBox='0 0 70 70'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle
                      cx='35'
                      cy='35'
                      r='35'
                      fill='#FFFFFF'
                    />
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M15.1424 20.482C15.68 19.7167 16.3903 19.0923 17.2143 18.6607C18.0383 18.229 18.9521 18.0025 19.88 18H50.12C52.0856 18 53.8013 18.9988 54.8576 20.482C55.5779 21.4978 56 22.7494 56 24.0924V45.9076C56 49.1992 53.438 52 50.12 52H19.88C16.562 52 14 49.1992 14 45.9076V24.0924C14 22.7494 14.42 21.4977 15.1424 20.4799V20.482ZM20.1593 22.25L33.446 37.0506C33.6428 37.2698 33.8827 37.4448 34.1504 37.5646C34.418 37.6844 34.7074 37.7463 35 37.7463C35.2926 37.7463 35.582 37.6844 35.8496 37.5646C36.1173 37.4448 36.3572 37.2698 36.554 37.0506L49.8407 22.25H20.1593ZM51.8 26.3895L39.662 39.9088C39.0715 40.5661 38.3518 41.0914 37.5489 41.4508C36.7461 41.8102 35.8779 41.9959 35 41.9959C34.1221 41.9959 33.2539 41.8102 32.4511 41.4508C31.6482 41.0914 30.9285 40.5661 30.338 39.9088L18.2 26.3895V45.9076C18.2 46.9999 19.0232 47.75 19.88 47.75H50.12C50.9768 47.75 51.8 46.9999 51.8 45.9076V26.3895Z'
                      fill='#BF0303'
                    />
                  </svg>
                </div>
              </>
            ) : (
              <>
                <label htmlFor='emailID'>
                  <span>
                    Почта <b>*</b>
                  </span>
                  <input
                    {...register('email', {
                      required: {
                        value: true,
                        message: 'Это поле обязательно',
                      },
                    })}
                    id='emailID'
                    type='email'
                    placeholder='user@email.com'
                  />
                  {errors['email'] && (
                    <span className={styles.error}>{errors['email'].message}</span>
                  )}
                </label>
                <div
                  className={styles.icon}
                  onClick={() => changeActiveField('phone')}
                >
                  <svg
                    width='70'
                    height='70'
                    viewBox='0 0 70 70'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle
                      cx='35'
                      cy='35'
                      r='35'
                      fill='#FFFFFF'
                    />
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M28.0225 40.9778C32.9444 45.8997 37.5159 48.6431 40.9175 50.1677C43.888 51.499 47.3937 50.4543 50.1264 47.7216L50.7507 47.0952L43.2426 42.3176C41.8157 44.3985 39.1169 45.6194 36.4224 44.4834C34.3819 43.6214 31.681 42.1478 29.2667 39.7335C26.8525 37.3192 25.381 34.6183 24.519 32.5778C23.3809 29.8832 24.5997 27.1845 26.6826 25.7576L21.9051 18.2494L21.2788 18.8736C18.546 21.6064 17.5035 25.1121 18.8327 28.0826C20.3572 31.4843 23.1006 36.0558 28.0225 40.9778V40.9778ZM39.1827 54.0428C35.3289 52.3165 30.3242 49.2844 25.0201 43.9802C19.716 38.6782 16.6838 33.6713 14.9555 29.8174C12.6729 24.7214 14.758 19.3917 18.2764 15.8712L18.9006 15.2448C19.3502 14.7949 19.8951 14.4518 20.4951 14.2407C21.0951 14.0296 21.7349 13.956 22.3671 14.0253C22.9994 14.0946 23.608 14.305 24.148 14.6411C24.6881 14.9771 25.1457 15.4301 25.4872 15.9668L31.4963 25.4157C31.6554 25.6663 31.7602 25.9476 31.804 26.2412C31.8477 26.5349 31.8293 26.8345 31.7501 27.1206C31.6709 27.4068 31.5325 27.6731 31.3439 27.9024C31.1554 28.1318 30.9208 28.319 30.6554 28.4521L29.3113 29.1252C28.3941 29.5838 28.2072 30.3928 28.4302 30.9258C29.15 32.6309 30.3539 34.8137 32.2691 36.7311C34.1865 38.6463 36.3693 39.8503 38.0744 40.5701C38.6073 40.7952 39.4163 40.6062 39.8749 39.6889L40.548 38.3448C40.6809 38.0792 40.868 37.8445 41.0973 37.6557C41.3265 37.4669 41.5928 37.3283 41.879 37.2489C42.1651 37.1694 42.4648 37.1509 42.7585 37.1945C43.0523 37.2381 43.3336 37.3428 43.5844 37.5018L53.0333 43.5131C53.5699 43.8546 54.0229 44.3122 54.359 44.8522C54.695 45.3923 54.9054 46.0009 54.9747 46.6332C55.044 47.2654 54.9704 47.9052 54.7593 48.5052C54.5483 49.1052 54.2051 49.6501 53.7552 50.0997L53.1288 50.724C49.6104 54.2445 44.2809 56.3275 39.1827 54.0428V54.0428Z'
                      fill='#BF0303'
                    />
                  </svg>
                </div>
              </>
            )}
          </div>
          <div className={clsx(styles.form_control)}>
            <Button
              disabled={false}
              loading={isAuthLoading}
              type={'submit'}
              className={`btn prime fw ${styles.submit}`}
            >
              Далее
            </Button>
          </div>
        </div>
      </form>
      <div className={styles.footer}>
        <div className={styles.footer_item}>
          <p>
            Нажимая "Далее" Вы соглашаетесь с{' '}
            <Link to={'/privacy'}>
              политикой конфиденциальности и правилами обработки персональных данных
            </Link>
          </p>
        </div>
        <div className={styles.footer_item}>
          <p>
            <Link to={'login'}>Войти по логину и паролю</Link>
          </p>
        </div>
      </div>
    </>
  )
}
export default PhoneForm
