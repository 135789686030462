import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { selectCurrentUser, logOut as logOutAction } from '../../../store/slices/auth/authSlice'
import { useLogOutMutation } from '../../../store/slices/auth/authApiSlice'
import { links } from '../../../utils/navLinks'
import { notify } from '../../../utils/notify'

import arrowIcon from '../../../assets/img/arrow.svg'

import styles from './profile-icon.module.scss'
import clsx from 'clsx'
import { useOutsideAlerter } from '../../../hooks/useOutsideAlerter'

const ProfileIcon = () => {
  const { pathname } = useLocation()
  const ref = useRef(null)
  const [showActions, setShowActions] = useState(false)
  const user = useSelector(selectCurrentUser)
  const [logOut] = useLogOutMutation()
  const dispatch = useDispatch()

  const onCloseActions = () => {
    setShowActions(false)
  }

  const actionsToggleHandler = () => {
    setShowActions(!showActions)
  }

  useOutsideAlerter(ref, onCloseActions)

  const logOutHandler = async () => {
    let confirm = window.confirm('Вы уверены что хотите выйти?')
    if (confirm) {
      const { data, isLoading, isSuccess, isError } = await logOut()
      if (!isError) {
        localStorage.removeItem('accessToken')
        dispatch(logOutAction())
        notify(data.message, 'success')
      }
    }
  }

  return (
    <div
      className={styles.profile}
      ref={ref}
    >
      <div
        className={styles.name}
        onClick={actionsToggleHandler}
      >
        <span>{user.name}</span>
        <div className={clsx(styles.icon, showActions && styles.icon_rotate)}>
          <img
            src={arrowIcon}
            alt='arrow icon'
          />
        </div>
      </div>
      <div className={clsx(styles.actions, showActions && styles.show)}>
        <div className={styles.actions_header}>
          <h4>{user.name}</h4>
          <p>{user.email}</p>
        </div>
        <div className={styles.actions_body}>
          {pathname !== '/' && (
            <Link
              className={styles.link}
              to='/'
              onClick={onCloseActions}
            >
              Главная
            </Link>
          )}
          {links.map((link, i) => {
            return (
              <Link
                key={i}
                className={styles.link}
                to={link.to}
                onClick={onCloseActions}
              >
                {link.title}
              </Link>
            )
          })}
        </div>
        <div className={styles.actions_footer}>
          <button onClick={logOutHandler}>Выйти</button>
        </div>
      </div>
    </div>
  )
}

export default ProfileIcon
