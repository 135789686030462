import React from 'react'

import styles from './agreement.module.scss'
import { Link } from 'react-router-dom'
import Releases from '../../components/ui/releases'

const AgreementPage = () => {
  return (
    <section className={styles.agreement}>
      <div className={styles.content}>
        <h4>Соглашение</h4>
        <div className={styles.part}>
          <h5>1. Общие положения</h5>
          <p>
            1.1. ООО «КАДР», именуемый в дальнейшем «Продавец», публикует Публичную оферту о продаже
            цифровых товаров, представленных на официальном интернет-сайте Продавца{' '}
            <Link to={'/'}>https://movie-hero.com</Link>
          </p>
          <p>
            1.2. В соответствии со статьей 437 Гражданского Кодекса Российской Федерации (ГК РФ)
            данный документ является публичной офертой, и в случае принятия изложенных ниже условий
            физическое лицо, производящее акцепт этой оферты, осуществляет оплату Цифровых Товаров
            Продавца в соответствии с условиями настоящего Договора. В соответствии с пунктом 3
            статьи 438 ГК РФ, оплата Цифрового Товара Покупателем является акцептом оферты, что
            считается равносильным заключению Договора на условиях, изложенных в оферте.
          </p>
          <p>
            1.3. На основании вышеизложенного, внимательно ознакомьтесь с текстом публичной оферты,
            и если вы не согласны с каким-либо пунктом оферты, Вам предлагается отказаться от
            покупки Цифровых Товаров или использования Услуг, предоставляемых Продавцом.
          </p>
          <p>
            1.4. В настоящей оферте, если контекст не требует иного, нижеприведенные термины имеют
            следующие значения:
          </p>
          <ul>
            <li>
              «Оферта» – публичное предложение Продавца, адресованное любому физическому лицу
              (гражданину), заключить с ним договор купли-продажи (далее – «Договор») на
              существующих условиях, содержащихся в Договоре, включая все его приложения.
            </li>
            <li>
              «Покупатель» – физическое лицо, заключившее с Продавцом Договор на условиях,
              содержащихся в Договоре.
            </li>
            <li>«Акцепт» – полное и безоговорочное принятие Покупателем условий Договора.</li>
            <li>
              «Цифровые Товары» – перечень наименований ассортимента, представленный на официальном
              интернет-сайте.
            </li>
            <li>
              «Заказ» – отдельные позиции из ассортиментного перечня Цифровых Товаров, указанные
              Покупателем при оформлении заявки на интернет-сайте.
            </li>
          </ul>
        </div>
        <div className={styles.part}>
          <h5>2. Предмет договора</h5>
          <p>
            2.1. Продавец открывает доступ для просмотра Цифрового Товара (сериала целиком или
            отдельных его серий и/или доступ к дополнительным материалам) в соответствии с
            действующим прейскурантом, опубликованным на интернет-сайте Продавца{' '}
            <Link to={'/'}>https://movie-hero.com</Link>, а Покупатель производит оплату и принимает
            Цифровой Товар в соответствии с условиями настоящего Договора.
          </p>
          <p>
            2.2. Настоящий Договор и приложения к нему являются официальными документами Продавца и
            неотъемлемой частью оферты.
          </p>
        </div>
        <div className={styles.part}>
          <h5>3. Оформление Заказа</h5>
          <p>
            3.1. Заказ Цифрового Товара осуществляется Покупателем через Интернет-сайт{' '}
            <Link to={'/'}>https://movie-hero.com</Link>
          </p>
          <p>
            3.2. При оформлении заказа на интернет-сайте Продавца Покупатель предоставляет
            персональную информацию указанную при регстрации: имя, адрес электронной почты.
          </p>
          <p>
            3.3. Продавец обязуется не сообщать данные Покупателя, указанные при регистрации на
            сайте <Link to={'/'}>https://movie-hero.com</Link> , лицам, не имеющим отношения к
            исполнению Заказа. Подробнее{' '}
            <Link to={'/privacy'}>"О политике конфиденциальности"</Link>
          </p>
          <p>
            Утвердив Заказ выбранного Цифрового Товара, Покупатель предоставляет Продавцу
            необходимую информацию в соответствии с порядком, указанном в п. 3.2. настоящего
            Договора.
          </p>
          <p>
            3.4. Продавец не несет ответственности за содержание и достоверность информации,
            предоставленной Покупателем при регистрации.
          </p>
          <p>
            3.5. Покупатель несёт ответственность за достоверность предоставленной информации при
            регистрации на интернет-ресурсе <Link to={'/'}>https://movie-hero.com</Link>.
          </p>
          <p>
            3.6. Оплата Покупателем самостоятельно оформленного на интернет-сайте Заказа означает
            согласие Покупателя с условиями настоящего Договора. День оплаты Заказа является датой
            заключения Договора купли-продажи между Продавцом и Покупателем.
          </p>
          <ul>
            3.7. Даты выхода серий:
            <li>
              <Releases title='' />
            </li>
          </ul>
          <p>
            3.8. В случае возникновения у Покупателя вопросов, касающихся свойств и характеристик
            Цифрового Товара, перед оформлением Заказа ему необходимо обратиться за консультацией к
            Продавцу по адресу электронной почты
            <a href='mailto:info@movie-hero.com'>info@movie-hero.com</a>.
          </p>
        </div>
        <div className={styles.part}>
          <h5>4. Сроки исполнения Заказа</h5>
          <p>
            4.1. Доступ к Цифровому Товару открывается Покупателю сразу после оформления и оплаты
            Заказа и становится доступен в личном кабинете по адресу{' '}
            <Link to={'/me/videos'}>https://movie-hero.com/me</Link>
          </p>
          <p>
            4.2. Каждая серия имеет дату премьеры и не может быть доступна раньше чем наступит срок
            премьеры. Ознакомьться с расписанием премьер можно по адресу{' '}
            <Link to={'/me/videos'}>https://movie-hero.com/me/videos</Link>
          </p>
          <p>
            4.3. В разделе <Link to={'/rates'}>Тарифы и цены</Link> находятся актуальные данные по
            предосталяемым подпискам. Каждый тариф содержит временной период активности. После
            истечения срока подписки необходимо оформить ее заново или подключить автопродление
          </p>
        </div>
        <div className={styles.part}>
          <h5>5. Оплата Заказа</h5>
          <p>
            5.1. Оплата Заказа осуществляется путем перечисления Покупателем денежных средств
            Продавцу через платежную форму, размещенную на сайте{' '}
            <Link to={'/'}>https://movie-hero.com</Link>. Подтверждением оплаты исполненного Заказа
            является информационное письмо, которое приходит Покупателю на электронную почту после
            оплаты Заказа.
          </p>
          <p>
            5.2. Цены на любые позиции Цифрового Товара, указанные на интернет-сайте{' '}
            <Link to={'/'}>https://movie-hero.com</Link>, могут быть изменены Продавцом в
            одностороннем порядке без уведомления Покупателя. В случае изменения цены на заказанные
            позиции Цифрового Товара, Продавец обязуется в кратчайшие сроки проинформировать
            Покупателя о таком изменении. Покупатель вправе подтвердить либо аннулировать Заказ.
          </p>
        </div>
        <div className={styles.part}>
          <h5>6. Возврат Заказа</h5>
          <p>
            6.1. Согласно пункту 14 Постановлению Правительства РФ от 19.01.1998 N 55 «Об
            утверждении Правил продажи отдельных видов товаров, перечня товаров длительного
            пользования, на которые не распространяется требование покупателя о безвозмездном
            предоставлении ему на период ремонта или замены аналогичного товара, и перечня
            непродовольственных товаров надлежащего качества, не подлежащих возврату или обмену на
            аналогичный товар других размера, формы, габарита, фасона, расцветки или комплектации»
            издания, воспроизводимые на технических носителях не подлежат возврату или обмену на
            аналогичный товар.
          </p>
          <p>
            6.2. Все фильмы и отдельные серии, доступ к которым вы приобретаете на нашем сайте,
            являются цифровыми товарами.
          </p>
          <p>6.3. Цифровые товары не подлежат возврату или обмену после завершения покупки.</p>
          <p>
            6.4. После покупки цифрового товара вы получаете мгновенный доступ к контенту, что
            делает невозможным его возврат.
          </p>
          <p>
            6.5. Мы не можем контролировать использование цифрового контента после его
            предоставления, поэтому возврат средств за такие товары не предусмотрен.
          </p>
          <p>
            6.6. В случае технических проблем с доступом к приобретенному фильму или отдельным(ой)
            сериям(ии), пожалуйста, свяжитесь с нашей службой поддержки по адресу{' '}
            <a href='mailto:support@movie-hero.com'>support@movie-hero.com</a>. Мы постараемся
            решить проблему в кратчайшие сроки.
          </p>
        </div>
        <div className={styles.part}>
          <h5>7. Авторские права</h5>
          <p>
            7.1. Вся текстовая информация, графические изображения и видео-файлы размещенные на
            интернет-сайте <Link to={'/'}>https://movie-hero.com</Link>, а также в содержимом
            Цифровых Товаров, являются собственностью Продавца.
          </p>
        </div>
        <div className={styles.part}>
          <h5>8. Права, обязанности и ответственность</h5>
          <p>
            8.1. Продавец не несет ответственности за ненадлежащее использование товаров
            Покупателем, заказанных на интернет-сайте.
          </p>
          <p>8.2 Покупатель НЕ имеет права:</p>
          <ul>
            <li>
              скачивать, распечатывать, распространять, передавать либо рассылать Материалы третьим
              лицам как полностью, так и частично;
            </li>
            <li>
              воспроизводить Материалы, то есть изготавливать экземпляры Материалов или их частей в
              любой форме, если это воспроизведение имеет цель дальнейшего распространения или
              коммерческое использования;
            </li>
            <li>распространять и продавать распечатанные Материалы Сайта Продавца;</li>
            <li>
              доводить Материалы до всеобщего сведения с использованием сети Интернет и прочих
              цифровых сетей;
            </li>
            <li>
              переделывать, изменять, стирать логотип Сайта Продавца, адрес Сайта Продавца и прямое
              указание Продавца «Только для личного использования» или прочим образом нарушать
              целостность Материалов интернет-сайта <Link to={'/'}>https://movie-hero.com</Link>
            </li>
          </ul>
          <p>
            8.3. Несоблюдение требований п.8.2. Договора является нарушением законодательства об
            авторском праве и преследуется по закону.
          </p>
          <p>
            8.4. В соответствии с п. 4 ст. 16 Федерального закона «Об информации, информационных
            технологиях и о защите информации» Продавец обязуется: предотвращать попытки
            несанкционированного доступа к информации и/или передачу ее лицам, не имеющим
            непосредственного отношения к исполнению Заказов; своевременно обнаруживать и пресекать
            такие факты.
          </p>
          <p>
            8.5. Все претензии по ненадлежащему исполнению заказа Покупатель вправе направить на
            адрес электронной почты, указанный на интернет-сайте{' '}
            <Link to={'/'}>https://movie-hero.com</Link>, в разделе "Контакты" или через форму
            обратной связи. Вся поступившая информация обрабатывается в кратчайшие сроки.
          </p>
          <p>
            Контактная информация: Email:{' '}
            <a href='mailto:info@movie-hero.com'>info@movie-hero.com</a>
          </p>
        </div>
      </div>
    </section>
  )
}

export default AgreementPage
