import noticeIcon from '../../../assets/img/notice.svg'

import styles from './releases.module.scss'

const Releases = ({ title }) => {
  return (
    <div className={styles.releases}>
      {title && <h4>{title}</h4>}
      <ul>
        <li>
          1 серия <br />
          <span>20 Сентября 2024</span>
        </li>
        <li>
          2 серия <br />
          <span>27 Сентября 2024</span>
        </li>
        <li>
          3 серия <br />
          <span>04 Октября 2024</span>
        </li>
        <li>
          4 серия <br />
          <span>11 Октября 2024</span>
        </li>
        <li>
          5 серия (конец 1 сезона) <br />
          <span>18 Октября 2024</span>
        </li>
      </ul>
      <div className={styles.notice}>
        <img
          src={noticeIcon}
          alt='Важно'
        />
        <p>Дата выхода серий может поменяться на усмотрение автора</p>
      </div>
    </div>
  )
}
export default Releases
