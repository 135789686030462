import React from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

import logoImg from '../../../assets/img/logo.svg'

import styles from './logo.module.scss'

const Logo = ({ variant }) => {
  return (
    <div className={clsx(styles.logo, styles[variant])}>
      <Link
        className={styles.link}
        to={'/'}
      >
        <img
          src={logoImg}
          alt='Герой'
        />
        <span>
          Народный Анимационный <br /> Фильм
        </span>
      </Link>
    </div>
  )
}

export default Logo
