import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import Header from './header'
import Footer from './footer'

import styles from './layout.module.scss'

const Layout = ({ children }) => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <div className={styles.layout}>
      <Header />
      <main className={styles.main}>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
